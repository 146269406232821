import {isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useStore} from "vuex";
import { FileTransfer } from "@ionic-native/file-transfer"
import {File} from "@ionic-native/file";
//import {Directory} from "@capacitor/filesystem";
import {AndroidPermissions} from "@ionic-native/android-permissions";
import {useI18n} from "vue-i18n";

export default function invoiceFunctions() {

    const{t} = useI18n()
    const store = useStore()
    const getInvoiceData = (id) => {
        return new Promise((resolve, reject) => {
            axios.get('/api/invoice/'+id)
                .then( (resp) => {
                    if(!resp.data.success){
                        reject(resp.data.message)
                    }
                    else{
                        resolve(resp.data.data)
                    }
                })
                .catch( err => {
                    reject(err.response ? err.response.status+' '+err.response.statusText : err.toString())
                })
        })
    }
    const invoiceStatusRender = (id) => {
        id = parseInt(id)
        if(id === 0) return {status_text: t('Created'), color: '#333'}
        const invoiceStatuses = store.state.general_data.invoice_statuses
        for(let x in invoiceStatuses){
            if(parseInt(invoiceStatuses[x].id) === id){
                return invoiceStatuses[x]
            }
        }
        return {status_text: t('Unknown'), color: '#333'}
    }
    const prepareInvoiceDownload = async (id) => {
        const loader = await loadingController.create({message: t('Please wait')})
        await loader.present()
        return axios.get('/api/invoice/'+id+'/download/prepare')
            .then( async (resp) => {
                if(!resp.data.success){
                    toastController.create({header: t('Error')+'!', message: resp.data.message, duration: 3500, color: 'danger'}).then((t)=> t.present())
                }
                else{
                    let fURL = (process.env.VUE_APP_API_ENDPOINT + 'api/advisor/invoice/' + id + '/' + resp.data.data.download_code + '/' + resp.data.data.cache_key_code + '/download')
                    fURL += '?lang='+(localStorage.getItem('lang') || '')
                    if(!isPlatform('hybrid')) {
                        window.location.href = fURL
                    }
                    else{ // iOS & Android download
                        if(isPlatform('android')){
                            let permissions = await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
                            // Ask for permissions
                            if(!permissions.hasPermission){
                                permissions = await AndroidPermissions.requestPermissions([AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE])
                            }
                            if( !permissions.hasPermission){
                                return
                            }
                        }
                        const fT = FileTransfer.create()
                        const l = await loadingController.create({message: 'Downloading...'})
                        await l.present()
                        fT.download(fURL, File.externalRootDirectory+'/Invoices'+'/'+resp.data.data.file_name)
                            .then(()=>{
                                toastController.create({header: t('Invoice downloaded'), message: t('pages.invoices.invoice_saved_msg'), duration:2500, color: "primary", position:'top'}).then((t)=>t.present())
                            })
                            .catch(()=>{
                                toastController.create({header: t('Error')+'!', message: t('errors.general_error'), duration: 3500, color: 'danger', position:'top'}).then((t)=> t.present())
                            })
                            .then(() => l.dismiss())
                    }
                }
            })
            .catch( err => {
                const msg = err.response ? err.response.status+' '+err.response.statusText : err.toString()
                toastController.create({header: t('Error')+'!', message: msg, duration: 3500, color: 'danger'}).then((t)=> t.present())
            })
            .then(() => loader.dismiss())
    }

    return {
        getInvoiceData,
        invoiceStatusRender,
        prepareInvoiceDownload,
    }
}
